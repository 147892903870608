import React from 'react'
import { injectIntl } from 'react-intl'
import { graphql } from 'gatsby'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/Header'
import Strengths from '../scenes/ArtificialIntelligence/Strengths'
import Displays from '../scenes/ArtificialIntelligence/Displays'
import OptimizeWorkflow from '../scenes/OptimizeWorkflow'
import SecurityMini from '../scenes/SecurityMini'
import Footer from '../scenes/Footer'

import headerPicture from '../img/artworks/artwork_features_miniblock_1.png'

const GATSBY_APP_URL = process.env.GATSBY_APP_URL

const ArtificalIntelligence = ({ intl: { formatMessage }, data }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.ai.title' })}
      metaDescription={formatMessage({ id: 'meta.ai.description' })}
      metaOGImage={headerPicture}
    />
    <Navigation />
    <Header
      crumbs={[
        { name: formatMessage({ id: 'breadcrumb.wuha' }), path: '/' },
        { name: formatMessage({ id: 'breadcrumb.features' }), path: '/features' },
        { name: formatMessage({ id: 'breadcrumb.ai' }), path: '/artificial-intelligence' },
      ]}
      artworkPicture={data.headerPicture.childImageSharp.fluid}
      artworkWidth={440}
      title={formatMessage({ id: 'ai.header.title' })}
      description={formatMessage({ id: 'ai.header.description' })}
      actionText={formatMessage({ id: 'ai.header.action.connect' })}
      actionURL={`${GATSBY_APP_URL}/sign-up?utm_source=wuha&utm_medium=cta&utm_content=ai-header`}
    />
    <Space type="giant" />
    <Strengths data={data} />
    <Space type="xl" />
    <Displays data={data} />
    <Space type="giant" />
    <OptimizeWorkflow
      utmContent="ai-optimize"
    />
    <Space type="giant" />
    <SecurityMini />
    <Footer />
  </Layout>
)

export const pageQuery = graphql`
  query {
    headerPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_1.png" }) {
      ...fluidImage
    }
    strength1Picture: file(relativePath: { eq: "artworks/artwork_aiPower_focus_1.png" }) {
      ...fluidImage
    }
    strength2Picture: file(relativePath: { eq: "artworks/artwork_aiPower_focus_2.png" }) {
      ...fluidImage
    }
    strength3Picture: file(relativePath: { eq: "artworks/artwork_aiPower_focus_3.png" }) {
      ...fluidImage
    }
    strength4Picture: file(relativePath: { eq: "artworks/artwork_aiPower_focus_4.png" }) {
      ...fluidImage
    }
    strength5Picture: file(relativePath: { eq: "artworks/artwork_aiPower_focus_5.png" }) {
      ...fluidImage
    }
    strength6FRPicture: file(relativePath: { eq: "artworks/artwork_aiPower_focus_6_fr.png" }) {
      ...fluidImage
    }
    strength6ENPicture: file(relativePath: { eq: "artworks/artwork_aiPower_focus_6_en.png" }) {
      ...fluidImage
    }
    googlePicture: file(relativePath: { eq: "artworks/artwork_features_integration_google.png" }) {
      ...fluidImage
    }
    barFRPicture: file(relativePath: { eq: "artworks/artwork_features_integration_bar_fr.png" }) {
      ...fluidImage
    }
    barENPicture: file(relativePath: { eq: "artworks/artwork_features_integration_bar_en.png" }) {
      ...fluidImage
    }
    wuhaPicture: file(relativePath: { eq: "artworks/artwork_features_integration_wuha_app.png" }) {
      ...fluidImage
    }
    slackPicture: file(relativePath: { eq: "artworks/artwork_features_integration_slack.png" }) {
      ...fluidImage
    }
  }
`

export default withContext(injectContext(withIntl(injectIntl(ArtificalIntelligence))))
