import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from '../../../../../components/Image'
import Inline from '../../../../../components/Inline'

const titleTextClass = classNames({
  'ai-displays-display-item-title-text': true,
})

class DisplayItem extends Component {

  render() {
    const {
      display,
      isSelected,
      onClick,
    } = this.props

    if (!display) {
      return null
    }

    const containerClass = classNames({
      'ai-displays-display-item-container': true,
      'ai-displays-display-item-container-selected': isSelected,
    })

    return (
      <div
        className={containerClass}
        onClick={onClick}
      >
        <Image
          src={display.icon}
          alt={display.id}
        />
        <Inline type="xs" />
        <p className={titleTextClass}>
          {display.title}
        </p>
      </div>
    )
  }
}

DisplayItem.propTypes = {
  display: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
}

DisplayItem.defaultProps = {
  display: undefined,
  isSelected: false,
  onClick: undefined,
}

export default DisplayItem
