import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import GatsbyImage from 'gatsby-image'

import Space from '../../../components/Space'
import DisplayItem from './components/DisplayItem'

import googleIcon from '../../../img/icons/icon_google.svg'
import barIcon from '../../../img/icons/icon_book.svg'
import wuhaIcon from '../../../img/icons/icon_wuha.svg'
import slackIcon from '../../../img/icons/icon_slack.svg'

const containerClass = classNames({
  'ai-displays-container': true,
})

const titleContainerClass = classNames({
  'ai-displays-title-container': true,
})

const backgroundClass = classNames({
  'ai-displays-image-background': true,
})

const imageContainerClass = classNames({
  'ai-displays-image-container': true,
})

const mobileContainerClass = classNames({
  'ai-displays-mobile-container': true,
})

class Displays extends Component {

  constructor(props) {
    super(props)

    this.state = {
      displays: [],
    }
  }

  componentDidMount() {
    const {
      data,
      intl: {
        formatMessage,
        locale,
      },
    } = this.props

    const barPicture = locale === 'fr' ?
      data.barFRPicture.childImageSharp.fluid :
      data.barENPicture.childImageSharp.fluid

    const displays = [
      {
        id: 'bar',
        icon: barIcon,
        picture: barPicture,
        title: formatMessage({ id: 'ai.displays.bar.title' }),
      },
      {
        id: 'google',
        icon: googleIcon,
        picture: data.googlePicture.childImageSharp.fluid,
        title: formatMessage({ id: 'ai.displays.google.title' }),
      },
      {
        id: 'wuha',
        icon: wuhaIcon,
        picture: data.wuhaPicture.childImageSharp.fluid,
        title: formatMessage({ id: 'ai.displays.wuha.title' }),
      },
      {
        id: 'slack',
        picture: data.slackPicture.childImageSharp.fluid,
        icon: slackIcon,
        title: formatMessage({ id: 'ai.displays.slack.title' }),
      },
    ]

    this.setState({
      displays,
      selectedDisplayIndex: 0,
    })
  }


  // Methods
  selectDisplay = (newIndex) => {
    this.setState({
      selectedDisplayIndex: newIndex,
    })
  }


  // Render
  render() {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <div className={containerClass}>
        <Container>
          <Row>
            <Col xs={12}>
              <Space type="xl" />
              <div className={titleContainerClass}>
                <h2 id="ai-displays">
                  {formatMessage({ id: 'ai.displays.title' })}
                </h2>
                <Space type="xxs" />
                <p>
                  {formatMessage({ id: 'ai.displays.description' })}
                </p>
              </div>
              <Space type="xl" />
            </Col>
          </Row>
        </Container>

        <Hidden xs sm>
          {this.renderDesktop()}
        </Hidden>

        <Visible xs sm>
          {this.renderMobile()}
        </Visible>
      </div>
    )
  }

  renderDesktop = () => {
    const {
      displays,
      selectedDisplayIndex,
    } = this.state

    const selectedDisplay = displays[selectedDisplayIndex]
    if (!selectedDisplay) {
      return null
    }

    return (
      <Fragment>
        <div className={backgroundClass}>
          <Container>
            <Row>
              <Col md={8} offset={{ md: 2 }}>
                <div className={imageContainerClass}>
                  <GatsbyImage
                    fluid={selectedDisplay.picture}
                    alt={selectedDisplay.id}
                    style={{
                      width: 794,
                      maxWidth: '100%',
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row>
            <Col xs={12}>
              <Space type="l" />
            </Col>
            {this.renderFeatureItems()}
          </Row>
        </Container>
      </Fragment>
    )
  }

  renderFeatureItems = () => {
    const {
      displays,
      selectedDisplayIndex,
    } = this.state

    const list = displays.map((display, index) => (
      <Col
        key={display.id}
        md={5}
        offset={{ md: (index & 1) ? 0 : 1 }}
      >
        <DisplayItem
          display={display}
          isSelected={index === selectedDisplayIndex}
          onClick={() => this.selectDisplay(index)}
        />
        <Space type="xxs" />
      </Col>
    ))

    return list
  }

  renderMobile = () => {
    const {
      displays,
    } = this.state

    const list = displays.map(display => (
      <div key={display.id}>
        <Col xs={12}>
          <GatsbyImage
            fluid={display.picture}
            alt={display.id}
            style={{
              maxWidth: '100%',
            }}
          />
          <Space type="xs" />
          <p>
            {display.title}
          </p>
        </Col>
      </div>
    ))

    return (
      <div className={mobileContainerClass}>
        <Space type="xl" />
        {list}
      </div>
    )
  }
}

export default injectIntl(Displays)
