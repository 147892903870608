import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Hidden, Visible, ScreenClassRender } from 'react-grid-system'
import GatsbyImage from 'gatsby-image'

import Button from '../../../components/Button'
import I18nLink from '../../../components/I18nLink'
import Space from '../../../components/Space'
import ArtworkCol from './components/ArtworkCol'
import ContentCol from './components/ContentCol'
import Logo from './components/Logo'
import Title from './components/Title'
import Description from './components/Description'

import activityIcon from '../../../img/icons/icon_activity.svg'
import layersIcon from '../../../img/icons/icon_layers.svg'
import cpuIcon from '../../../img/icons/icon_cpu.svg'
import shareIcon from '../../../img/icons/icon_share.svg'
import soundIcon from '../../../img/icons/icon_sound.svg'
import shapeIcon from '../../../img/icons/icon_shape.svg'
import smallArrowRightBlueIcon from '../../../img/icons/icon_small_arrow_right_blue.svg'

const containerClass = classNames({
  'ai-strengths-container': true,
})

class Strengths extends Component {

  render() {
    return (
      <div className={containerClass}>
        <Container>
          {this.renderSmartDisplay()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderFilters()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderDatesAndFormats()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderSimilarResults()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderNLP()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderTypology()}
        </Container>
      </div>
    )
  }

  renderSmartDisplay = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <ContentCol
          md={4}
          mdOffset={0}
        >
          <Logo
            picture={activityIcon}
            alt={formatMessage({ id: 'ai.strengths.smartDisplay.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'ai.strengths.smartDisplay.id' })}
            text={formatMessage({ id: 'ai.strengths.smartDisplay.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'ai.strengths.smartDisplay.description' })} />
        </ContentCol>
        {this.renderArtwork(data.strength1Picture.childImageSharp.fluid, 459, formatMessage({ id: 'ai.strengths.smartDisplay.alt' }), 7, 1)}
      </Row>
    )
  }

  renderFilters = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Hidden xs sm>
          {this.renderArtwork(data.strength2Picture.childImageSharp.fluid, 433, formatMessage({ id: 'ai.strengths.filters.alt' }), 6, 0)}
        </Hidden>

        <ContentCol
          md={4}
          mdOffset={2}
        >
          <Logo
            picture={layersIcon}
            alt={formatMessage({ id: 'ai.strengths.filters.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'ai.strengths.filters.id' })}
            text={formatMessage({ id: 'ai.strengths.filters.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'ai.strengths.filters.description' })} />
        </ContentCol>

        <Visible xs sm>
          {this.renderArtwork(data.strength2Picture.childImageSharp.fluid, 433, formatMessage({ id: 'ai.strengths.filters.alt' }), 6, 0)}
        </Visible>
      </Row>
    )
  }

  renderDatesAndFormats = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <ContentCol
          md={4}
          mdOffset={0}
        >
          <Logo
            picture={cpuIcon}
            alt={formatMessage({ id: 'ai.strengths.datesAndFormats.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'ai.strengths.dates.id' })}
            text={formatMessage({ id: 'ai.strengths.dates.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'ai.strengths.dates.description' })} />
          <Space type="m" />
          <Title
            id={formatMessage({ id: 'ai.strengths.formats.id' })}
            text={formatMessage({ id: 'ai.strengths.formats.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'ai.strengths.formats.description' })} />
        </ContentCol>
        {this.renderArtwork(data.strength3Picture.childImageSharp.fluid, 480, formatMessage({ id: 'ai.strengths.datesAndFormats.alt' }), 6, 2)}
      </Row>
    )
  }

  renderSimilarResults = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Hidden xs sm>
          {this.renderArtwork(data.strength4Picture.childImageSharp.fluid, 459, formatMessage({ id: 'ai.strengths.similarResults.alt' }), 7, 0)}
        </Hidden>

        <ContentCol
          md={4}
          mdOffset={1}
        >
          <Logo
            picture={shareIcon}
            alt={formatMessage({ id: 'ai.strengths.similarResults.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'ai.strengths.similarResults.id' })}
            text={formatMessage({ id: 'ai.strengths.similarResults.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'ai.strengths.similarResults.description' })} />
        </ContentCol>

        <Visible xs sm>
          {this.renderArtwork(data.strength4Picture.childImageSharp.fluid, 459, formatMessage({ id: 'ai.strengths.similarResults.alt' }), 7, 0)}
        </Visible>
      </Row>
    )
  }

  renderNLP = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <ContentCol
          md={4}
          mdOffset={0}
        >
          <Logo
            picture={soundIcon}
            alt={formatMessage({ id: 'ai.strengths.nlp.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'ai.strengths.nlp.id' })}
            text={formatMessage({ id: 'ai.strengths.nlp.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'ai.strengths.nlp.description' })} />
          <Space type="xl" />
          <I18nLink to={formatMessage({ id: 'ai.strengths.nlp.action.uri' })}>
            <ScreenClassRender render={screenClass => (
              <Button
                text={formatMessage({ id: 'ai.strengths.nlp.action.readMore' })}
                color="outline"
                iconRight={smallArrowRightBlueIcon}
                isExpanded={['xs', 'sm'].includes(screenClass)} />
            )}
            />
          </I18nLink>
        </ContentCol>
        {this.renderArtwork(data.strength5Picture.childImageSharp.fluid, 444, formatMessage({ id: 'ai.strengths.nlp.alt' }), 7, 1)}
      </Row>
    )
  }

  renderTypology = () => {
    const {
      data,
      intl: {
        formatMessage,
        locale,
      },
    } = this.props

    const picture = locale === 'fr' ?
      data.strength6FRPicture.childImageSharp.fluid :
      data.strength6ENPicture.childImageSharp.fluid
    const altText = formatMessage({ id: 'ai.strengths.typology.alt' })

    return (
      <Row>
        <Hidden xs sm>
          {this.renderArtwork(picture, 459, altText, 7, 0)}
        </Hidden>

        <ContentCol
          md={4}
          mdOffset={1}
        >
          <Logo
            picture={shapeIcon}
            alt={altText}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'ai.strengths.typology.id' })}
            text={formatMessage({ id: 'ai.strengths.typology.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'ai.strengths.typology.description' })} />
        </ContentCol>

        <Visible xs sm>
          {this.renderArtwork(picture, 459, altText, 7, 0)}
        </Visible>
      </Row>
    )
  }

  renderArtwork = (src, height, alt, md = 6, mdOffset = 0) => {
    return (
      <ArtworkCol
        xs={12}
        md={md}
        mdOffset={mdOffset}
      >
        <Visible xs sm>
          <Space type="s" />
        </Visible>
        <GatsbyImage
          fluid={src}
          alt={alt}
          style={{
            height: 'auto',
            maxHeight: height,
            maxWidth: '100%',
          }}
        />
      </ArtworkCol>
    )
  }
}

export default injectIntl(Strengths)
